<template>
  <section
    id="captcha"
    class="captcha">
    <h2
      type="text"
      id="mainCaptcha"
      @copy.prevent></h2>
    <input
      class="btn"
      type="button"
      id="refresh"
      value="Refrescar"
      @click="createCaptcha" />
    <input
      type="text"
      class="input"
      @paste.prevent
      id="txtInput" />
    <p
      :class="{'active': !isValid}"
      class="message">
      captcha invalido, por favor vuelva a intentar
    </p>
    <input
      id="Button1"
      class="btn btn-primary"
      type="button"
      value="No soy robot"
      @click="validCaptcha" />
  </section>
</template>

<script>
export default {
  name: 'Captcha',
  data () {
    return {
      isValid: true
    }
  },
  mounted () {
    this.createCaptcha()
  },
  methods: {
    createCaptcha () {
      const alpha = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
        'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
        '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      let i
      for (i = 0; i < 6; i++) {
        var a = alpha[Math.floor(Math.random() * alpha.length)]
        var b = alpha[Math.floor(Math.random() * alpha.length)]
        var c = alpha[Math.floor(Math.random() * alpha.length)]
        var d = alpha[Math.floor(Math.random() * alpha.length)]
        var e = alpha[Math.floor(Math.random() * alpha.length)]
        var f = alpha[Math.floor(Math.random() * alpha.length)]
        var g = alpha[Math.floor(Math.random() * alpha.length)]
      }

      const code = a + ' ' + b + ' ' + ' ' + c + ' ' + d + ' ' + e + ' ' + f + ' ' + g
      document.getElementById('mainCaptcha').innerHTML = code
      document.getElementById('mainCaptcha').value = code
    },
    validCaptcha () {
      var string1 = this.removeSpaces(document.getElementById('mainCaptcha').value)
      var string2 = this.removeSpaces(document.getElementById('txtInput').value)
      const isValid = string1 === string2
      this.isValid = isValid
      this.$emit('resultCaptcha', isValid)
    },
    removeSpaces (string) {
      return string.split(' ').join('')
    }
  }
}
</script>

<style scoped lang="scss">
@import '../scss/variables';

* {
  box-sizing: border-box;
}

.captcha{
  background-color: #f2f2f2;
  margin: 0 auto;
  padding: 50px 100px 30px;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0,0 ,0, .25);
  text-align: center;
}

.captcha .btn {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border: 1px solid $primary-color;
  color: $primary-color;
  border-radius: 50px;
  width: 100%;
  margin: 30px auto;
  background: #FFF;
  cursor: pointer;
}

.captcha .btn-primary {
  background: $primary-color;
  color: #FFF;
}

.captcha .btn-primary:hover {
  background: #FFF;
  color: $primary-color;
}

.captcha .btn:hover {
  background: $primary-color;
  color: #FFF;
}

.captcha .input {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border: 1px solid $primary-color;
  color: $primary-color;
  border-radius: 6px;
  width: 100%;
  margin: 30px auto 16px;
  background: #FFF;
  outline: none;
  padding: 0 15px;
  color: #585858;
  letter-spacing: 1px;
  text-align: center;
}

.captcha .message {
  color: #9c0805;
  font-size: 12px;
  opacity: 0;
  transition: .4s all;
}

.captcha .message.active {
  opacity: 1;
}
</style>
