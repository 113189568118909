<template>
  <section
    class="reports">
    <Search
      v-if="!isEnabledCaptcha"
      :list="publicReportsForSearch"
      @selectReport="selectReport($event)" />
    <div
      v-if="isEnabledCaptcha"
      class="content">
      <captcha @resultCaptcha="setResultCaptcha($event)"/>
    </div>
    <div v-else class="content">
      <button class="button-menu" @click="toggleMenu">
        <img src="../assets/menu.svg" alt="menu">
      </button>
      <aside
        class="reports__aside">
        <div>
          <MenuReports
            :one-level="publicReports.data"
            :active="isOpenMenu"
            :id-current-report="currentReportId"
            class="menul"
            @selectReport="selectReport($event)"
            @selectAllReport="selectAllReports($event)" />
        </div>
      </aside>

      <div
        id="reports-show"
        class="reports__show">
        <Report
          :ref="`report${report.id}`"
          v-for="(report, index) in currentPublicReports.data"
          :key="index"
          :id="`report${report.id}`"
          :data="{
            url: report.token_embed_url,
            subscriptionKey: report.token_embed_key,
            embedReportId: report.embed_report_id,
            embedUrl: report.embed_url
          }"
          @click="handleClickReport" />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import MenuReports from '../components/MenuReports.vue'
import Report from '../components/Report.vue'
import Captcha from '../components/Captcha.vue'
import Search from '../components/Search.vue'

export default {
  name: 'Reports',
  components: {
    MenuReports,
    Report,
    Captcha,
    Search
  },
  data () {
    return {
      isOpenMenu: true,
      isEnabledCaptcha: false
    }
  },
  created () {
    const isPublicView = this.$route.name === 'PublicReports'
    this.$store.commit('setIsPublicView', isPublicView)
    const existResultCaptcha = JSON.parse(window.sessionStorage.getItem('resultLastCaptcha'))
    this.isEnabledCaptcha = !existResultCaptcha
  },
  computed: {
    ...mapState(['publicReports', 'currentPublicReports']),
    ...mapGetters(['publicReportsForSearch']),
    currentReportId () {
      return this.currentPublicReports.data?.length ? this.currentPublicReports.data[0].id : null
    }
  },
  methods: {
    toggleMenu () {
      this.isOpenMenu = !this.isOpenMenu
    },
    selectReport (reports) {
      this.$store.commit('setCurrentPublicReports', { data: [] })
      setTimeout(() => {
        this.$store.commit('setCurrentPublicReports', { data: reports })
        this.$store.dispatch('sendAccessPublicReport')
      }, 0)
    },
    selectAllReports (reports) {
      this.$store.commit('setCurrentPublicReports', { data: [] })
      setTimeout(() => {
        this.$store.commit('setCurrentPublicReports', { data: reports })
      }, 0)
    },
    setResultCaptcha (isValid) {
      window.sessionStorage.setItem('resultLastCaptcha', isValid)
      this.isEnabledCaptcha = !isValid
      this.$store.commit('setIsValidCaptcha', isValid)
    },
    handleClickReport () {
      if (this.currentReports.data) {
        this.$store.dispatch('sendStatsPublicReport')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';

.reports {
  margin-top: 20px;
  .content {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: 20px;
  }

  &__aside {
    position: relative;
  }

  &__show {
    width: calc(100% - 330px);
    margin-top: 20px;
    transition: .3s;

    @media (max-width: 766px) {
      width: 100%;
    }
  }

  @media (max-width: 766px) {
    .search {
      margin-bottom: 80px;
    }
  }
}

.button-menu {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: $primary-color;
  border: 0;
  cursor: pointer;
  position: absolute;
  left: 16px;
  top: -60px;
  display: none;

  @media (max-width: 766px) {
    display: block;
  }
}

@media (max-width: 766px) {
  .menul {
    position: absolute;
    left: 0;
    top: 30px;
  }
}

</style>
