export default {
  privateReportsForSearch ({ reports }) {
    const result = []
    reports.data.forEach((item) => {
      item.options.forEach((subitem) => {
        subitem.options.forEach((report) => {
          result.push({
            levelOneName: item.name,
            levelOneDescription: item.description,
            levelTwoName: subitem.name,
            levelTwoDescription: subitem.description,
            ...report
          })
        })
      })
    })
    return result
  },
  publicReportsForSearch ({ publicReports }) {
    const result = []
    publicReports.data.forEach((item) => {
      item.options.forEach((subitem) => {
        subitem.options.forEach((report) => {
          result.push({
            levelOneName: item.name,
            levelOneDescription: item.description,
            levelTwoName: subitem.name,
            levelTwoDescription: subitem.description,
            ...report
          })
        })
      })
    })
    return result
  }
}
