<template>
  <div
    :id="`reportContainer${id}`"
    class="report-container" />
</template>

<script>
import * as pbi from 'powerbi-client'
import axios from 'axios'
import $ from 'jquery'

export default {
  name: 'Report',
  props: {
    id: {
      type: String
    },
    data: {
      type: Object
    },
    customConfig: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      config: {},
      isMobile: {
        Android: function () {
          return navigator.userAgent.match(/Android/i)
        },
        iOS: function () {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i)
        },
        Windows: function () {
          return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i)
        }
      }
    }
  },
  computed: {
    isAny () {
      return (this.isMobile.Android() || this.isMobile.iOS() || this.isMobile.Windows())
    }
  },
  mounted () {
    if (this.data.embedUrl) {
      this.configureReport()
      this.getReport()
    }
    if (window.matchMedia('(max-width: 766px)').matches) {
      var widthBuffer = 10
      var heightBuffer = 10
      $(`#reportContainer${this.id}`).height($(window).height() - (heightBuffer))
      $(`#reportContainer${this.id}`).width($(window).width() - widthBuffer)
      $(window).resize(function () {
        $(`#reportContainer${this.id}`).height($(window).height() - (heightBuffer))
        $(`#reportContainer${this.id}`).width($(window).width() - widthBuffer)
      })
    }
  },
  methods: {
    configureReport () {
      const models = pbi.models

      this.config = {
        type: 'report',
        id: this.data.embedReportId,
        embedUrl: this.data.embedUrl,
        tokenType: models.TokenType.Aad,
        permissions: models.Permissions.Read,
        viewMode: models.ViewMode.View,
        settings: {
          filterPaneEnabled: false,
          layoutType: this.isAny ? models.LayoutType.MobilePortrait : models.LayoutType.Custom,
          background: this.isAny ? models.BackgroundType.Transparent : models.BackgroundType.Default,
          customLayout: {
            displayOption: models.DisplayOption.FitToPage
          },
          panes: {
            pageNavigation: {
              visible: true,
              position: models.PageNavigationPosition.Left
            }
          }
        }
      }
    },
    async getReport () {
      try {
        const response = await axios.get(this.data.url, {
          headers: { 'portalbi-Subscription-Key': this.data.subscriptionKey }
        })
        this.renderReport(response.data.access_token)
      } catch (error) {
        if (this.$state) {
          this.$state.commit('setGlobalError', {
            error: `Un error ha ocurrido al cargar el reporte. Detalle : ${error}`
          })
        }
        Array.from(document.getElementsByTagName('iframe')).forEach(it => {
          it.style.border = 0
        })
      }
    },
    renderReport (accessToken) {
      const $reportContainer = document.getElementById(`reportContainer${this.id}`)
      const powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      )
      const reportRefer = powerbi.embed($reportContainer, {
        ...this.config,
        ...this.customConfig,
        accessToken
      })
      var _this = this
      reportRefer.on('dataSelected', () => {
        _this.$emit('click')
      })
      document.getElementsByTagName('iframe').forEach(it => {
        it.style.border = 0
      })
    },
    resetReport () {
      const $reportContainer = document.getElementById(`reportContainer${this.id}`)
      const powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      )
      powerbi.reset($reportContainer)
    }
  }
}
</script>

<style lang="scss" scoped>
.report-container {
  width: 100%;
  height: 900px;
  min-width: 967px;

  @media(max-width: 766px) {
    min-width: initial;
  }

  iframe {
    border: 0 !important;
  }
}
</style>
