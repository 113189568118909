import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Reports from '../views/Reports.vue'
import PublicReports from '../views/PublicReports.vue'
import Login from '../views/Login.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/reportes',
    name: 'Reports',
    component: Reports,
    beforeEnter (to, from, next) {
      const token = localStorage.getItem('token')
      store.state.isLogged || token ? next() : next({ name: 'Home' })
    }
  },
  {
    path: '/reportes-publicos',
    name: 'PublicReports',
    component: PublicReports
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((toRoute, _, next) => {
  if (toRoute.path.includes('public')) {
    window.document.title = 'Portal BI - Público'
  } else if (toRoute.path === '/reportes') {
    window.document.title = 'Portal BI - Privado'
  } else {
    window.document.title = 'Portal BI'
  }
  next()
})

export default router
