<template>
  <section class="reports">
    <Search
      :list="privateReportsForSearch"
      @selectReport="selectReport($event)" />
    <div class="content">
      <button class="button-menu" @click="toggleMenu">
        <img src="../assets/menu.svg" alt="menu">
      </button>
      <aside class="reports__aside">
        <MenuReports
          :one-level="reports.data"
          :active="isOpenMenu"
          :id-current-report="currentReportId"
          class="menul"
          @selectReport="selectReport($event)"
          @selectAllReport="selectAllReports($event)" />
      </aside>

      <div
        id="reports-show"
        class="reports__show">
        <Report
          :ref="`report${report.id}`"
          v-for="(report, index) in currentReports.data"
          :key="index"
          :id="`report${report.id}`"
          :data="{
            url: report.token_embed_url,
            subscriptionKey: report.token_embed_key,
            embedReportId: report.embed_report_id,
            embedUrl: report.embed_url
          }"
          @click="handleClickReport" />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import MenuReports from '../components/MenuReports.vue'
import Report from '../components/Report.vue'
import Search from '../components/Search.vue'

export default {
  name: 'Reports',
  components: {
    MenuReports,
    Report,
    Search
  },
  data () {
    return {
      isOpenMenu: true
    }
  },
  computed: {
    ...mapState(['reports', 'currentReports']),
    ...mapGetters(['privateReportsForSearch']),
    currentReportId () {
      return this.currentReports.data?.length ? this.currentReports.data[0].id : null
    }
  },
  methods: {
    toggleMenu () {
      this.isOpenMenu = !this.isOpenMenu
    },
    selectReport (reports) {
      this.$store.commit('setCurrentReports', { data: [] })
      setTimeout(() => {
        this.$store.commit('setCurrentReports', { data: reports })
        if (reports && reports[0].stats_url.includes('private_reports')) {
          this.$store.dispatch('sendAccessPrivateReport')
        } else {
          this.$store.dispatch('sendAccessCrossReport')
        }
      }, 0)
    },
    selectAllReports (reports) {
      this.$store.commit('setCurrentReports', { data: [] })
      setTimeout(() => {
        this.$store.commit('setCurrentReports', { data: reports })
      }, 0)
    },
    handleClickReport () {
      if (this.currentReports.data) {
        if (this.currentReports.data[0].stats_url?.includes('private_reports')) {
          this.$store.dispatch('sendStatsPrivateReport')
        } else {
          this.$store.dispatch('sendStatsCrossReport')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';

.reports {
  .content {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: 0px;
    min-height: 300px;
  }

  &__aside {
    position: relative;
  }

  &__show {
    width: 100%;
    margin-top: 20px;
    transition: .3s;
  }
}

.button-menu {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #004884;
  border: 0;
  cursor: pointer;
  position: absolute;
  left: 16px;
  top: -40px;

  @media(max-width: 670px) {
    top: -104px;
  }
}

.menul {
  position: absolute;
  left: 0;
  top: 30px;
}

</style>
