<template>
  <span></span>
</template>

<script>
export default {
  name: 'Login',
  created () {
    const { code, state } = this.$route.query
    this.$store.commit('setLoginUrl', { code, state })
    this.$store.dispatch('getTokenLoginWithCode')
    this.$router.push('/')
  }
}
</script>

<style lang="scss" scoped>

</style>
