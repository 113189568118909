import AuthClient from './clients/AuthClient'

export default {
  login () {
    return AuthClient.get('msal/login_url')
  },
  getTokenLoginWithCode ({ code, state }) {
    return AuthClient.post('msal/login_with_code', {
      code,
      state
    })
  }
}
