import ApiRepository from './ApiRepository'
import AuthRepository from './AuthRepository'
import ApiPublicRepository from './ApiPublicRepository'
import TextRepository from './TextRepository'

const repositories = {
  auth: AuthRepository,
  api: ApiRepository,
  public: ApiPublicRepository,
  text: TextRepository
}

export default {
  get: (name) => repositories[name]
}
