<template>
  <div class="search" v-click-outside="onClickOutside">
    <input
      class="search__input"
      type="text"
      placeholder="Buscador de reportes"
      v-model="search"
      @input="handleSearch"
      @click="isVisibleResults = true" />
    <button
      @click="clearSearch"
      class="search__close">
      <img src="../assets/close-button.png" alt="">
    </button>
    <transition mode="out-in">
      <ul
        v-if="results.length && isVisibleResults"
        class="search__list">
        <li
          class="search__item"
          v-for="result in results"
          :key="result.refIndex"
          @click="selectReport(result)">
          <p class="title">
            {{ result.item.name }}
          </p>
          <div class="info">
            <p>{{ result.item.levelOneName }} / {{ result.item.levelTwoName }}</p>
          </div>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue'
import Fuse from 'fuse.js'
import { mapState } from 'vuex'

Vue.directive('click-outside', {
  bind (el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})

export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    search: null,
    results: [],
    isVisibleResults: false,
    options: {
      includeScore: true,
      includeMatches: true,
      findAllMatches: true,
      minMatchCharLength: 3,
      threshold: 0.3,
      keys: [
        'name',
        'description',
        'levelOneName',
        'levelOneDescription',
        'levelTwoName',
        'levelTwoDescription'
      ]
    }
  }),
  computed: {
    ...mapState([''])
  },
  methods: {
    handleSearch () {
      const fuse = new Fuse(this.list, this.options)
      this.results = fuse.search(this.search)
      this.isVisibleResults = true
    },
    selectReport (report) {
      this.search = report.item.name
      this.$emit('selectReport', [report.item])
      this.handleSearch()
      this.onClickOutside()
    },
    onClickOutside () {
      this.isVisibleResults = false
    },
    clearSearch () {
      this.search = null
      this.results = []
    }
  }
}
</script>

<style lang="scss">
  .search {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    display: block;
    &__close {
      position: absolute;
      right: 0;
      top: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 30px;
        height: auto;
      }
    }
    &__input {
      box-shadow: 1px 0px 5px 0px #e8e8e8;
      border-radius: 4px;
      border: 1px solid #dadada;
      padding: 12px 16px;
      width: 100%;
      display: block;
      box-sizing: border-box;
      color: #004884;
    }
    &__list {
      width: 100%;
      margin: 15px auto 10px;
      position: absolute;
      top: 38px;
      z-index: 11;
      border-radius: 4px;
      padding-left: 0;
      background: #fff;
      box-shadow: 1px 1px 14px rgba(0, 0, 0, .16);
      border: 1px solid rgba(0, 0, 0, .12);
    }
    &__item {
      width: 100%;
      list-style: none;
      padding: 10px 20px;
      background: #fff;
      border-bottom: 1px solid #dadada;
      cursor: pointer;
      transition: .4s;
      &:hover {
        background: #e6effd;
        p {
          color: #004884;
        }
      }
      .title {
        margin: 0;
        color: #004884;
      }
      .info {
        display: flex;
        font-size: 12px;
        color: #969696;
        p {
          transition: .4s;
          margin-top: 6px;
          margin-bottom: 0;
        }
      }
    }
  }

  .v-leave { opacity: 1; }

  .v-leave-active { transition: opacity .5s; }

  .v-leave-to { opacity: 0; }

  .v-enter { opacity: 0; }

  .v-enter-active { transition: opacity .5s; }

  .v-enter-to { opacity: 1; }

</style>
