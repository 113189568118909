export default {
  loginUrl: {
    url: null,
    code: null,
    state: null
  },
  globalError: {
    error: null
  },
  isLogged: false,
  isLoading: false,
  userSession: null,
  reports: {
    data: [],
    isLoading: false
  },
  publicReports: {
    data: [],
    isLoading: false
  },
  currentReports: {
    data: null
  },
  currentPublicReports: {
    data: null
  },
  isValidCaptcha: false,
  isPublicView: false,
  texts: {}
}
