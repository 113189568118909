import ApiClient from './clients/ApiClient'

export default {
  getUserProfile () {
    return ApiClient.get('user/profile/')
  },
  getReports () {
    return ApiClient.get('user/menu/')
  },
  sendStatsLogin () {
    return ApiClient.post('stats/login/')
  },
  sendStatsPublicReport (data) {
    return ApiClient.post('stats/public_reports/', data)
  },
  sendStatsPrivateReport (data) {
    return ApiClient.post('stats/private_reports/', data)
  },
  sendStatsCrossReport (data) {
    return ApiClient.post('stats/cross_reports/', data)
  }
}
