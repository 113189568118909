<template>
  <footer class="footer pb-footer-background">
    <div class="content-xl">
      <div class="footer__line"></div>

      <div class="footer__images">
        <figure>
          <img
            alt="Portal BI"
            class="img-fluid header__portal-bi"
            id="portalBi"
            width="168"
            height="50"
            src="/static/logo1-footer.png">
        </figure>
        <figure>
          <img
            width="200"
            height="60"
            src="/static/logo2-footer.png"
            alt="sfc">
        </figure>
        <figure>
          <img
            width="380"
            height="44"
            src="/static/logo3-footer.png"
            alt="sfc">
        </figure>

      </div>
      <div class="footer__links">
        <p class="pb-footer-color">{{ texts.texto3 }}</p>
        <a class="pb-footer-color" href="/admin">Admin</a>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Footer',
  computed: {
    ...mapState(['texts'])
  },
  created () {
    this.getTexts()
  },
  methods: {
    ...mapActions(['getTexts'])
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';

.footer {
  padding: 20px 0 20px;
  text-align: center;
  margin-top: 100px;

  &__line {
    width: 100%;
    border-top: 1px solid #d2cece;
  }

  &__images {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    widows: 100%;
    padding: 0 20px;

    p {
      font-size: 12px;
      color: #626262;
    }

    a {
      padding: 8px;
      margin: 0 18px;
      color: $primary-color;
      transition: .4s;

      &:hover {
        color: $primary-color-50;
      }
    }
  }
}
</style>
