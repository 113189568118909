import axios from 'axios'

const externalApiBase = ''

const apiPublic = axios.create({
  baseURL: `${externalApiBase}/api/`
})

const response = (res) => res

const handleError = (error) => {
  return Promise.reject(error)
}

apiPublic.interceptors.response.use(response, handleError)

export default apiPublic
