import axios from 'axios'

const externalApiBase = ''

const apiClient = axios.create({
  baseURL: `${externalApiBase}/api/`
})

const injectToken = async (config) => {
  try {
    const token = localStorage.getItem('token')
    const newConfig = config
    newConfig.headers.authorization = `JWT ${token}`
    return newConfig
  } catch (error) {
    return Promise.reject(error)
  }
}

const response = (res) => res

const handleError = (error) => {
  return Promise.reject(error)
}

apiClient.interceptors.response.use(response, handleError)
apiClient.interceptors.request.use(injectToken)

export default apiClient
